/**
 * Created by huck on 15.06.18.
 */

import IComment from './comment';

export enum SectionType {
  /* -- Summary ---*/
  //Definition
  Definition = 'Definition',
  //Formel
  Rules = 'Rules',
  //Formel ohne !
  Rules2 = 'Rules2',
  // Beispiel
  Example = 'Example',
  // Vorgehen
  Steps = 'Steps',
  //Tipps
  Tipps = 'Tipps',
  //Text
  Text = 'Text',
  // Regel
  Rule = 'Rule',
  // Tipp
  Tip = 'Tip',
  // Herleitung
  Derivation = 'Derivation',
  // Streberwissen
  NerdKnowledge = 'NerdKnowledge',
  // Latex
  Latex = 'Latex',
  /* --- Exam --- */
  ExamSolution = 'ExamSolution',
  HighlightedExamSolution = 'HighlightedExamSolution',
  ExamText = 'ExamText',
  AdminsOnly = 'AdminsOnly'
}

export const ChapterSectionTypes = [
  SectionType.Text,
  SectionType.Rules,
  SectionType.Definition,
  SectionType.Example,
  SectionType.Steps,
  SectionType.Tipps,
  SectionType.Tip,
  SectionType.Rules2,
  SectionType.Rule,
  SectionType.Derivation,
  SectionType.NerdKnowledge,
  SectionType.Latex,
];
export const AssignmentSectionTypes = [
  SectionType.ExamSolution,
  SectionType.ExamText,
  SectionType.AdminsOnly,
];

export const AllSectionTypes = ChapterSectionTypes.concat(
  AssignmentSectionTypes,
);

export enum SectionImagePosition {
  insideBox = 0,
  belowBox = 1,
  aboveBox = 2
}

export interface ISectionTypeInfo {
  defaultTitle: string;
  imagePosition: SectionImagePosition;
  cssClasses: string[];
  boxCssClasses: string[];
  classString: string;
  boxClassString: string;
  expandable: boolean;
  type: SectionType;
}

const EXPANDABLE_SECTION_TYPES = [
  SectionType.ExamSolution,
  SectionType.HighlightedExamSolution,
];

const NON_PROGRASSABLE_SECTION_TYPES = [
  SectionType.ExamText,
  SectionType.AdminsOnly,
];

const NON_SUBSECTIONABLE_SECTION_TYPES = [
  SectionType.ExamText,
  SectionType.AdminsOnly,
];

export class SectionTypeInfo implements ISectionTypeInfo {
  constructor(public type: SectionType) {}

  public get classString(): string {
    return this.cssClasses.join(' ');
  }

  public get boxClassString(): string {
    return this.boxCssClasses.join(' ');
  }

  public get includeInProgress(): boolean {
    return NON_PROGRASSABLE_SECTION_TYPES.includes(this.type) === false;
  }

  public get includeSections(): boolean {
    return NON_SUBSECTIONABLE_SECTION_TYPES.includes(this.type) === false;
  }

  public get defaultTitle(): string {
    const titles = {
      [SectionType.Definition]: 'app.course.section-title.definition',
      [SectionType.Rules]: 'app.course.section-title.formula',
      [SectionType.Rules2]: 'app.course.section-title.formula-no-exclamation-mark',
      [SectionType.Example]: 'app.course.section-title.example',
      [SectionType.Steps]: 'app.course.section-title.procedure',
      [SectionType.Tipps]: 'app.course.section-title.remember',
      [SectionType.Tip]: 'app.course.section-title.tip',
      [SectionType.Rule]: 'app.course.section-title.rule',
      [SectionType.Derivation]: 'app.course.section-title.derivation',
      [SectionType.NerdKnowledge]: 'app.course.section-title.nerd-knowledge',
      [SectionType.ExamSolution]: 'app.course.section-title.solution',
      [SectionType.HighlightedExamSolution]: 'app.course.section-title.highlighted-solution',
      [SectionType.ExamText]: 'app.course.section-title.exercise-text',
      [SectionType.AdminsOnly]: 'app.course.section-title.admins-only',
    };

    return titles[this.type] || '';
  }

  public get expandable(): boolean {
    return EXPANDABLE_SECTION_TYPES.includes(this.type);
  }

  public get cssClasses(): string[] {
    const classes = {
      [SectionType.Definition]: ['definition-body'],
      [SectionType.Rules]: [
        'rules-body',
        'col-md',
        'container-fluid',
        'bodybox',
      ],
      [SectionType.Rules2]: [
        'rules-body',
        'col-md',
        'container-fluid',
        'bodybox',
      ],
      [SectionType.Rule]: [
        'rules-body',
        'col-md',
        'container-fluid',
        'bodybox',
      ],
      [SectionType.Steps]: ['example-box', 'steps'],
      [SectionType.Example]: ['example-box'],
      [SectionType.Tipps]: ['tipps-body', 'container-flex'],
      [SectionType.Tip]: ['tipps-body', 'container-flex', 'tip'],
      [SectionType.Derivation]: ['example-box'],
      [SectionType.NerdKnowledge]: [
        'tipps-body',
        'container-flex',
        'nerd-knowledge',
      ],
      [SectionType.ExamSolution]: [],
      [SectionType.HighlightedExamSolution]: ['rules-body', 'container-flex'],
    };

    return classes[this.type] || [];
  }

  public get boxCssClasses(): string[] {
    const classes = {
      [SectionType.ExamSolution]: ['example-box'],
      [SectionType.HighlightedExamSolution]: ['example-box'],
    };

    return classes[this.type] || [];
  }

  public get imagePosition(): SectionImagePosition {
    const positions = {};
    return positions[this.type] || SectionImagePosition.insideBox;
  }
}

export function classesForChapterSectionType(
  sectionType: SectionType,
): string[] {
  switch (sectionType) {
    case SectionType.Example:
      return ['example-box'];
    case SectionType.Definition:
      return ['definition-body'];
    case SectionType.Steps:
      return ['example-box'];
    case SectionType.Tipps:
      //   alert("im a tipp")
      return ['tipps-body container-flex'];
    case SectionType.Rules:
      return ['rules-body container-flex'];
    case SectionType.Rules2:
      return ['rules-body container-flex'];
    default:
      return [];
  }
}

export function defaultTitleForChapterSectionType(
  sectionType: SectionType,
): string {
  switch (sectionType) {
    case SectionType.Definition:
      return 'Definition';
    case SectionType.Rules:
      return 'Formel';
    case SectionType.Rules2:
      return 'Formel ohne \'!\'';
    case SectionType.Example:
      return 'Beispiel';
    case SectionType.Steps:
      return 'Vorgehen';
    case SectionType.Tipps:
      return 'Merke!';
    case SectionType.Tip:
      return 'Tipp';
    case SectionType.Rule:
      return 'Regel';
    case SectionType.Derivation:
      return 'Herleitung';
    case SectionType.NerdKnowledge:
      return 'Streberwissen';

    default:
      return null;
  }
}

export enum SectionImageSize {
  small = 0,
  medium = 1,
  large = 2
}

export const sectionImageSizeToImageSize = {
  [SectionImageSize.small]: 'small',
  [SectionImageSize.medium]: 'medium',
  [SectionImageSize.large]: 'large',
};
// Legacy, use only by cloudinary images
export type ISectionImagePixelSize = {
  height: number;
  width: number;
};

export enum IVideoDisplayMode {
  collapsed = 0,
  expanded = 1,
  playing = 2
}

export interface ISectionImage {
  url: string;
  size: SectionImageSize;
  illustration?: string;
  description?: string;
  pixelSize?: ISectionImagePixelSize;
}

export interface IVideoWrapper {
  url?: string;
  public_id?: string;
  description?: string;
  displayMode: IVideoDisplayMode;
  isVimeo?: boolean;
}

export enum ChatbotDisabledBy {
  NotDisabled = '',
  DataQualityDetection = 'data-quality',
  ManualInterference = 'manual',
}

export enum DataQualityReason {
  NoError = '',
  NoContent = 'no-content',
  TranscriptionFail = 'transcription-fail',
  NoSolution = 'no-solution',
  NoTask = 'no-task',
  InsertionError = 'insertion-error',
}

export interface ICustomSectionStyle {
  type: 'class' | string;
  value: string;
}

export enum SectionStyleCustomClasses {
  NoBackground = 'no-background',
}

export default interface ISection {
  _id: string;
  title?: string;
  hasTitle?: boolean;
  type?: SectionType;
  content?: string;
  _editorContent?: string;
  _editorTitle?: string;
  images?: ISectionImage[];
  mobileImages?: ISectionImage[];
  videos?: IVideoWrapper[];
  _videos?: IVideoWrapper[];
  fullURLs?: string[];
  editorContent?: string;
  editorTitle?: string;
  comments?: IComment[] | string[];
  stats?: {
    commentCount: number;
  };
  order?: number;
  chatbotDisabledBy?: ChatbotDisabledBy;
  dataQualityReason?: DataQualityReason;
  customStyles?: ICustomSectionStyle[];
}
