<div class="breadcrumbs" [attr.data-test-id]="TEST_IDS.ROOT">
  <div class="breadcrumbs__path">
    <a routerLink="/mycourses" [attr.data-test-id]="TEST_IDS.MAIN_COURSE">
      {{ 'app.header.course-area' | translate }}
    </a>

    <ng-container *ngIf="!home && _course && !checkout">
      <a routerLink="/courses/course/{{_course?.url_slug}}">
        <i class="icon fas fa-angle-right"></i>
        {{(course | async)?.title}}
      </a>

      <a
        *ngIf="_column && !showProgress()"
        routerLink="/courses/course/{{_course?.url_slug}}/{{_column.url_slug}}/{{_column.chapters?.[0]?.url_slug}}"
      >
        <i class="icon fas fa-angle-right"></i>
        {{_column?.title}}
      </a>

      <a *ngIf="showProgress()">
        <i class="icon fas fa-angle-right"></i>
        {{ 'app.common.learning-overview' | translate }}
      </a>
    </ng-container>

    <a *ngIf="checkout">
      <i class="icon fas fa-angle-right"></i>
      {{ 'app.header.buy' | translate}}
    </a>
  </div>

  <div class="header">
    <div class="heading">
      <h4 class="breadcrumbs__title" *ngIf="checkout">{{ 'app.header.buy' | translate }}</h4>
      <h4 class="breadcrumbs__title" *ngIf="!checkout">
        {{(heading | async) | translate: { course: _course?.title } }}
      </h4>
      <h4
        class="breadcrumbs__title"
        *ngIf="!checkout && learningOverview && !(heading | async)"
      >
        {{ 'app.common.learning-overview' | translate }} {{_course?.title}}:
      </h4>
      <h4
        class="breadcrumbs__title"
        *ngIf="!checkout && !learningOverview && !(heading | async)"
      >
        {{ 'app.header.course-welcome' | translate}}
      </h4>
    </div>
    <div class="meta">
      <div *ngIf="_course && showProgress()" class="total-progress">
        <span class="label">{{'app.header.progress-processed-label' | translate}}</span>
        <progress-bar
          [progress]="getProgress"
        ></progress-bar>
      </div>
    </div>
  </div>
</div>
