import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpService } from './http.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppVersionInterceptorProvider } from './app-version.interceptor';
import { HealthCheckService } from './health-check.service';
import { PreventNavigationGuard } from './prevent-navigation.guard';
@NgModule({ declarations: [], imports: [CommonModule], providers: [
        HttpService,
        AppVersionInterceptorProvider,
        HealthCheckService,
        PreventNavigationGuard,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class HttpModule {}
