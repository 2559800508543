import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';


@Component({
    selector: 'app-bookmark',
    template: `
      <span class="bookmark" [ngClass]="{
          bookmarked: bookmarked,
          loading: loading,
          disabled: disabledBy,
        }" tabindex="0" (click)="toggle()" (keydown.enter)="toggle()" (keydown.space)="toggle()"
            [matTooltip]="disabledBy"
      >
          <i class="fa-bookmark current-state" [ngClass]="{ 'fa-regular': !bookmarked, 'fa-solid': bookmarked, }"></i>
          <i class="fa-bookmark next-state" [ngClass]="{ 'fa-regular': bookmarked, 'fa-solid': !bookmarked, }"></i>
        </span>
  `,
    imports: [
        NgClass,
        MatTooltip,
    ],
    styleUrls: ['bookmark.component.scss'],
})
export class BookmarkComponent {
  @Input() bookmarked: boolean = false;
  @Input() loading: boolean = false;
  @Input() disabledBy: string | null = null;

  @Output() onBookmark = new EventEmitter<void>();
  @Output() onUnBookmark = new EventEmitter<void>();

  public toggle() {
    if (this.loading || this.disabledBy) {
      return;
    }
    if (this.bookmarked) {
      this.onUnBookmark.emit();
    } else {
      this.onBookmark.emit();
    }
  }
}
