import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
    name: 'sanitizeHtml',

})
export class SanitizeHTMLPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  public transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
        case 'html':
          return this.sanitizer.bypassSecurityTrustHtml(value);
        case 'style':
          return this.sanitizer.bypassSecurityTrustStyle(value);
        default:
          throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}

@NgModule({
  declarations: [],
  imports: [CommonModule, SanitizeHTMLPipe],
  exports: [SanitizeHTMLPipe],
})
export class SanitizeHTMLPipeModule {}
