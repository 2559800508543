import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RenderMathPipeModule } from 'app/shared/pipes/renderMath';

import { SectionComponent } from './components/section/section.component';
import { CommentsComponent } from './components/comments/comments.component';
import { CommentComponent } from './components/comment/comment.component';

import { AccordionModule } from '@components/accordion/accordion.module';
import { VideoPlayerModule } from '@components/video-player/video-player.module';

import { ModalsModule } from '@components/modals/modals.module';
import { SanitizeHTMLPipeModule } from '@pipe/sanitizeHTML.pipe';
import { CommentTextComponent } from './components/comment-text/comment-text.component';
import { ProgressModule } from '@components/progress/progress.module';
import { MediaCaptureComponent } from '@components/media-capture/media-capture.component';
import { ChatbotComponent } from '@components/section/components/chatbot/chatbot.component';
import { SectionBottomTabsComponent } from '@components/section/components/tabs/section-bottom-tabs.component';
import { SectionBottomTabComponent } from '@components/section/components/tabs/tab/section-bottom-tab.component';
import { SparkleComponent } from '@components/section/components/sparkle/sparkle.component';
import { MediaContentComponent } from './components/media-content/media-content.component';
import { VideoContentComponent } from './components/media-content/components/video-content/video-content.component';
import { MatTooltip } from '@angular/material/tooltip';
import { ButtonGroupComponent } from '@components/button-group/button-group.component';
import { DisableChatbotComponent } from '@components/section/components/disable-chatbot/disable-chatbot.component';
import { ChatbotDisabledReasonComponent } from './components/chatbot-disabled-reason/chatbot-disabled-reason.component';
import { CustomStylesComponent } from '@components/section/components/custom-styles/custom-styles.component';
import { BookmarkComponent } from '@components/bookmark/bookmark.component';
import { SectionImageComponent } from './components/section-image/section-image.component';
import { RouterLink } from '@angular/router';
import { UserNoteComponent } from '@components/user-note/user-note.component';
import { TranslatePipe } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SectionComponent,
    CommentsComponent,
    CommentComponent,
    CommentTextComponent,
    SectionBottomTabsComponent,
    SectionBottomTabComponent,
    ChatbotComponent,
    SparkleComponent,
    CommentTextComponent,
    MediaContentComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    VideoPlayerModule,
    RenderMathPipeModule,
    ProgressModule,
    AccordionModule,
    ModalsModule.forChild(),
    SanitizeHTMLPipeModule,
    MediaCaptureComponent,
    VideoContentComponent,
    MatTooltip,
    ButtonGroupComponent,
    DisableChatbotComponent,
    ChatbotDisabledReasonComponent,
    CustomStylesComponent,
    BookmarkComponent,
    SectionImageComponent,
    RouterLink,
    UserNoteComponent,
    TranslatePipe,
  ],
  exports: [
    SectionComponent,
    CommentsComponent,
    CommentComponent,
    SectionBottomTabsComponent,
    SectionBottomTabComponent,
    ChatbotComponent,
    SparkleComponent,
  ],
})
export class SectionModule {}
