import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import BasicRxComponent from '@components/BasicRxComponent';
import {
  ISectionImage,
  SectionImageSize,
  sectionImageSizeToImageSize,
} from '@models/section';
import { Platforms, PlatformService } from '@services/platform.service';
import { BehaviorSubject } from 'rxjs';

const screenSizesToImageeSize = {
  [Platforms.DESKTOP]: SectionImageSize.large,
  [Platforms.TABLET]: SectionImageSize.medium,
  [Platforms.MOBILE]: SectionImageSize.small,
};

@Component({
    selector: 'app-section-image',
    imports: [CommonModule],
    template: '<img [src]="src$ | async" [alt]="image.description" loading="lazy" />',
    styleUrl: './section-image.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionImageComponent extends BasicRxComponent implements OnInit {
  @Input() image: ISectionImage;

  public src$ = new BehaviorSubject<string | null>(null);
  private size = new BehaviorSubject<string | null>(null);

  constructor(private platformService: PlatformService) {
    super();
  }

  ngOnInit(): void {
    this.setSize(this.image.size);

    if (this.isCloudinaryImage) {
      this.src$.next(this.image.url);
      return;
    }

    this.bag.add(
      this.platformService.platform$.subscribe((imageSize: Platforms) => {
        const size = screenSizesToImageeSize[imageSize];
        this.setSize(size);
      }),
    );
    this.bag.add(
      this.size.subscribe((size) => {
        this.src$.next(`${this.image.url}?size=${size}`);
      }),
    );
  }

  private get isCloudinaryImage() {
    return this.image.url.includes('cloudinary.com');
  }

  private setSize(imageSize: SectionImageSize) {
    this.size.next(sectionImageSizeToImageSize[imageSize]);
  }
}
