import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { ModalsModule } from '@components/modals/modals.module';
import { FormsModule } from '@angular/forms';
import { IDialog } from '@components/modals/modal.types';
import { ModalsService } from '@components/modals/modals.service';
import { IChapter } from '@models/chapter';
import ISection from '@models/section';
import { Observable } from 'rxjs';
import { IChapterUserdata } from '@models/course-userdata';
import { UserdataService } from '@services/userdata';
import { TranslatePipe } from '@ngx-translate/core';


@Component({
    selector: 'app-user-note',
    template: `
    <span class="user-note" [ngClass]="{
          empty: isEmpty,
          disabled: disabledBy,
        }" tabindex="0" (click)="openModal()" (keydown.enter)="openModal()" (keydown.space)="openModal()"
          [matTooltip]="disabledBy"
    >
          <i class="fa-note-sticky" [ngClass]="{ 'fa-regular': isEmpty, 'fa-solid': !isEmpty, }"></i>
        </span>
    <app-dialog
      #modal
      [id]="modalService.MODAL_IDS.DYNAMIC_MODAL"
      [title]="'app.user.note-modal-heading' | translate"
      [closable]="false"
      (onCancel)="closeModalWithoutSaving()"
      (onSubmit)="closeModalAndSave()"
      [submitText]="'app.user.note-modal-submit' | translate"
    >
      <textarea class="user-note-input" [(ngModel)]="editContent" [maxlength]="maxLength"></textarea>
      <div class="user-note-input-info">
        <div class="user-note-input-info__visibility">
          {{ 'app.user.note-modal-visibility-info' | translate }}
        </div>
        <div class="user-note-input-info__length">{{editContent?.length || 0}}/{{maxLength}}</div>
      </div>
    </app-dialog>
  `,
    imports: [
        NgClass,
        MatTooltip,
        ModalsModule,
        FormsModule,
        TranslatePipe,
    ],
    styleUrls: ['user-note.component.scss'],
})
export class UserNoteComponent implements OnChanges, OnInit {
  @ViewChild('modal') modal: IDialog;

  @Input() content: string = '';
  @Input() disabledBy: string | null = null;
  @Input({ required: true }) chapter: IChapter;
  @Input() section: ISection;

  @Output() onChange = new EventEmitter<IChapterUserdata>();

  public modalService = inject(ModalsService);
  public userdataService = inject(UserdataService);

  public readonly maxLength = 500;
  public editContent: string = '';

  public get isEmpty() {
    return !this.content?.trim();
  }

  public openModal() {
    this.modal.open();
  }

  public closeModalWithoutSaving() {
    this.modal.remove();
    this.editContent = this.content;
  }

  public closeModalAndSave() {
    let requestObservable: Observable<IChapterUserdata>;
    if (this.section) {
      requestObservable = this.userdataService.setSectionUserNote(this.chapter, this.section._id, this.editContent);
    } else {
      requestObservable = this.userdataService.setChapterUserNote(this.chapter, this.editContent);
    }

    requestObservable.subscribe((result) => {
      this.modal.remove();
      if (this.section) {
        this.onChange.emit(result);
      } else {
        this.onChange.emit(result);
      }
    });
  }

  ngOnInit(): void {
    this.editContent = this.content;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.content) {
      this.editContent = changes.content.currentValue;
    }
  }
}
