import { Component, Input, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-avatar',
    template: `
    <i
      class="fa fa-user avatar__placeholder"
      [ngClass]="{ avatar__placeholder_hidden: (isLoaded | async) === true }"
    ></i>
    <img
      class="avatar__img"
      [ngClass]="{ avatar__img_hidden: (isLoaded | async) === false }"
      [src]="url"
      (load)="onSuccessLoad()"
      alt=""
    />
  `,
    styleUrls: ['./avatar.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: false,
})
export class AvatarComponent {
  @Input() url = '';

  public isLoaded = new BehaviorSubject(false);

  onSuccessLoad() {
    this.isLoaded.next(true);
  }
}
