import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import BasicRxComponent from '@components/BasicRxComponent';
import { IDialog } from '@components/modals/modal.types';
import { ModalsService } from '@components/modals/modals.service';
import { filter } from 'rxjs/operators';

const OPEN_CLASS_NAME = 'open';
type ControlsVariants = 'fill' | 'outline';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: false,
})
export class AppDialogComponent
  extends BasicRxComponent
  implements IDialog, OnDestroy
{
  @Input() closable = true;
  @Input() cancelable = true;
  @Input() submittable = true;
  @Input() submitText = 'app.common.modal-submit-button-default';
  @Input() cancelText = 'app.common.modal-cancel-button-default';
  @Input() title = '';
  @Input() show = false;
  @Input() id: string;
  @Input() titleAlign: 'center' | 'left' = 'center';
  @Input() backdropBackground: 'fill' | 'transparent' = 'fill';
  @Input() contentAlign: 'top' | 'center' = 'center';
  @Input() titleClass = '';
  @Input() submitBtnVariant: ControlsVariants = 'fill';
  @Input() cancelBtnVariant: ControlsVariants = 'outline';
  @Input() closeOnSubmit = true;
  @Input() closeOnCancel = true;

  @Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private el: ElementRef,
    private modalService: ModalsService,
  ) {
    super();
  }

  public getBtnClass(variant: ControlsVariants) {
    return `btn accent ${variant === 'outline' ? 'outline' : ''}`;
  }

  public get titleClassName() {
    return `title ${this.titleClass}`;
  }

  public onBackdropClick(): void {
    if (this.closable) {
      this.onCloseClick();
    }
  }



  public onCancelClick() {
    this.onCancel.emit();
    this.closeOnCancel && this.close();
  }

  public onCloseClick(): void {
    this.onClose.emit();
    this.close();
  }

  public get rootElement(): HTMLDivElement {
    return this.el.nativeElement;
  }

  public open(): void {
    // ensure id attribute exists
    const isModalIdValid = Object.values(this.modalService.MODAL_IDS).includes(
      this.id,
    );
    if (!isModalIdValid) {
      console.error('modal must have an id');
      return;
    }

    if(this.modalService.isModalAlreadyExists(this.id)) {
      this.modalService.remove(this.id);
    }
    // move element to modalContainer, so it can be displayed above everything else
    this.document
      .querySelector('#modalContainer')
      .appendChild(this.el.nativeElement);

    const onCloseSubscription = this.modalService.onClose$
      .pipe(filter((id) => id === this.id))
      .subscribe(() => {
        this.el.nativeElement.classList.remove(OPEN_CLASS_NAME);
      });

    this.bag.add(onCloseSubscription);
    this.el.nativeElement.classList.add(OPEN_CLASS_NAME);
  }

  public accept(): void {
    if (this.submittable) {
      this.onSubmit.emit();
      this.closeOnSubmit && this.close();
    }
  }

  public close(): void {
    // Call modal service to handle modal close
    this.modalService.close(this);
  }

  public remove() {
    try {
      this.document
        .querySelector('#modalContainer')
        .removeChild(this.el.nativeElement);
    } catch (e) {
      // was already removed
    }
  }

  public ngOnDestroy(): void {
    this.modalService.close(this);
  }
}
