import { BehaviorSubject } from 'rxjs';
import { IChapter } from '@models/chapter';
import { ISearchResult } from '@models/course';

export interface IChatSuggestion {
  text: string;
}

export interface AiMessage {
  text: BehaviorSubject<string>;
  ai: boolean;
  isLoading?: BehaviorSubject<boolean>;
  error?: boolean;
  chapterLinks?: BehaviorSubject<ISearchResult[]>;
  chatSuggestions?: BehaviorSubject<IChatSuggestion[]>;
}

export enum ChatTarget {
  Course = 'course',
  Chapter = 'chapter',
  Section = 'section',
}

export type ChatbotQueryType = 'content' | 'location' | 'course_info';

export interface ChatRequest {
  question: string;
  targetType: ChatTarget;
  targetId: string;
  queryType?: ChatbotQueryType;
}

export type Feedback = 'positive' | 'negative';

export interface ChatSystemMessage {
  sessionId?: string;
  limitReached?: boolean;
  chapterLinks?: Partial<IChapter>[];
}

export interface IChatSuggestionMessageResponse {
  text: string;
  suggestions: IChatSuggestion[];
}
