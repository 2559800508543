import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';

import { ModalsService } from '../../modals.service';
import { IDialog } from '../../modal.types';
import { filter } from 'rxjs/operators';
import BasicRxComponent from '@components/BasicRxComponent';
import { DOCUMENT } from '@angular/common';

const enum CLASS_NAMES {
  ROOT = 'app-portal',
  OPEN = 'app-portal_open'
}

@Component({
    selector: 'app-portal',
    styleUrls: ['./portal.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    template: `<div class="${CLASS_NAMES.ROOT}">
      <div
        class="${CLASS_NAMES.ROOT}__body"
        [style.min-width.px]="bodyMinWidth"
      >
        <ng-content></ng-content>
      </div>
    </div>
    <div class="${CLASS_NAMES.ROOT}__backdrop"></div>`,
    standalone: false,
})
export class PortalComponent
  extends BasicRxComponent
  implements OnDestroy, IDialog
{
  @Input() id: string;
  @Input() closeOnBackdrop: boolean = true;
  @Input() bodyMinWidth: number | null = null;
  @Output() onClose = new EventEmitter<void>();

  constructor(
    private modalService: ModalsService,
    private el: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
  }

  get rootElement(): HTMLDivElement {
    return this.el.nativeElement;
  }

  remove() {
    this.document.body.removeChild(this.el.nativeElement);
  }

  // remove self from modal service when directive is destroyed
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.modalService.remove(this.id);
  }

  // open modal
  open(): void {
    // ensure id attribute exists
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    this.document.body.appendChild(this.el.nativeElement);

    // close modal on background click
    this.el.nativeElement.addEventListener('click', (e: MouseEvent) => {
      const targetElement = e.target as Element;
      if (this.closeOnBackdrop && targetElement.className === CLASS_NAMES.ROOT) {
        this.close();
      }
    });

    const onCloseSubscription = this.modalService.onClose$
      .pipe(filter((id) => id === this.id))
      .subscribe(() => {
        this.onClose.emit();
        this.el.nativeElement.style.display = 'none';
        this.document.body.classList.remove(CLASS_NAMES.OPEN);
      });

    this.bag.add(onCloseSubscription);

    this.el.nativeElement.style.display = 'block';
    document.body.classList.add(CLASS_NAMES.OPEN);
  }

  // close modal
  close(): void {
    this.modalService.close(this);
  }
}
