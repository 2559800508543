import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter, inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ProgressState } from '@models/profile';
import { Observable } from 'rxjs';
import { LoginService } from '@core/auth/login.service';
import { IProgressableControl } from '../../types';
import { ActivityService } from '@services/activity.service';

@Component({
    selector: 'app-progress-controls',
    templateUrl: './progress-controls.component.html',
    styleUrls: ['./progress-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false,
})
export class ProgressControlsComponent
  implements IProgressableControl, OnInit, OnChanges
{
  @Output() public notUnderstood = new EventEmitter<void>();
  @Output() public partlyUnderstood = new EventEmitter<void>();
  @Output() public fullyUnderstood = new EventEmitter<void>();

  @Input() public progressState: Observable<ProgressState>;
  @Input() public compact = false;
  @Input() public additionalButtonClass = '';
  @Input() public style = '';
  @Input() public parentEvent = '';
  @Input() public isUpdating = false;

  private activityService = inject(ActivityService);
  private loginService: LoginService = inject(LoginService);


  public isPartlyUnderstood: boolean;
  public isFullyUnderstood: boolean;
  public isNotUnderstood: boolean;
  public ProgressState = ProgressState;
  public console = console;
  public isAnonymous: boolean = this.loginService.isAnonymous.value;
  showEvaluation: boolean;

  ngOnInit(): void {
    this.refresh();
  }

  ngOnChanges(): void {
    this.refresh();
  }

  get isObservable(): boolean {
    return this.progressState instanceof Observable;
  }

  showEvaluationContainer(event: Event): void {
    this.showEvaluation = true;
    event.stopPropagation();
  }

  makeEvaluate(evaluate: EventEmitter<void>): void {
    if (!this.isAnonymous) {
      evaluate.emit();
      this.showEvaluation = false;
    }
  }

  emitNotUnderstood(event): void {
    event.target.focus();
    if (!this.isAnonymous) {
      this.notUnderstood.emit();
      this.activityService.add('progress', ProgressState.notUnderstood.toString());
    }
  }

  emitPartlyUnderstood(event): void {
    event.target.focus();
    if (!this.isAnonymous) {
      this.partlyUnderstood.emit();
      this.activityService.add('progress', ProgressState.partlyUnderstood.toString());
    }
  }

  emitFullyUnderstood(event): void {
    event.target.focus();
    if (!this.isAnonymous) {
      this.fullyUnderstood.emit();
      this.activityService.add('progress', ProgressState.fullyUnderstood.toString());
    }
  }

  private refresh(): void {
    if (!(this.progressState instanceof Observable)) {
      this.isFullyUnderstood =
        this.progressState == ProgressState.fullyUnderstood;
      this.isPartlyUnderstood =
        this.progressState == ProgressState.partlyUnderstood;
      this.isNotUnderstood = this.progressState == ProgressState.notUnderstood;
    }
    if (this.parentEvent) {
      this.showEvaluation = false;
    }

    if (!this.showEvaluation) {
      this.parentEvent = '';
    }
  }
}
