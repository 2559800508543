import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProgressableControl } from '../../types';
import { ProgressState } from '@models/profile';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-progress',
    template: `
    <div class="prgs" [class.has-label]="!!title">
      <span class="understood-label">{{ title }}</span>

      <app-progress-controls
        [additionalButtonClass]="'smiley'"
        [compact]="true"
        [progressState]="progressState"
        (notUnderstood)="notUnderstood.emit($event)"
        (partlyUnderstood)="partlyUnderstood.emit($event)"
        (fullyUnderstood)="fullyUnderstood.emit()"
        [parentEvent]="parentEvent"
        [isUpdating]="isUpdating"
        class="desktop-app-progress-controls"
      ></app-progress-controls>
    </div>
  `,
    styleUrls: ['./progress.component.scss'],
    standalone: false,
})
export class ProgressComponent implements IProgressableControl {
  @Input() title = '';

  @Input() public progressState: Observable<ProgressState>;
  @Input() public compact = false;
  @Input() public additionalButtonClass = '';
  @Input() public style = '';
  @Input() public parentEvent = '';
  @Input() public isUpdating = false;

  @Output() public notUnderstood = new EventEmitter<void>();
  @Output() public partlyUnderstood = new EventEmitter<void>();
  @Output() public fullyUnderstood = new EventEmitter<void>();
}
