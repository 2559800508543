/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { LoginService } from './login.service';
import { AUTHENTICATED, ERROR_REASON, getReason } from '@core/http';
import { WHITE_LISTED_DOMAINS } from './auth.constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public auth: LoginService, public router: Router) {}

  private requestQueue = new Map<string, HttpRequest<any>>();

  private get isAuthRefreshInProgress() {
    return this.auth.refreshInProgress;
  }

  private logoutAndRedirect() {
    this.auth.logout();
  }

  private getAuthRequest(req: HttpRequest<any>) {
    return req.clone({
      url: req.url,
      setHeaders: {
        Authorization: `Bearer ${this.auth.idToken}`,
      },
    });
  }

  private refreshToken(): Observable<any> {
    return from(this.auth.tryToRefreshToken()).pipe(
      map((isRefreshedSuccessfully) => {
        if (!isRefreshedSuccessfully) {
          this.logoutAndRedirect();
          return of();
        }
        return of();
      }),
    );
  }

  private checkAuthorisation(
    error: HttpErrorResponse,
    reason: ERROR_REASON | string | undefined,
  ) {
    if (reason === ERROR_REASON.TOKENREFRESH) {
      if (this.isAuthRefreshInProgress.getValue()) {
        return this.isAuthRefreshInProgress.pipe(
          filter((inProgress) => !inProgress),
          first(),
        );
      }
      return this.refreshToken();
    }
    this.logoutAndRedirect();
    return throwError(error);
  }

  private isDomainInWhiteLists(req: HttpRequest<any>) {
    let whiteListed = false;

    for (const domain of WHITE_LISTED_DOMAINS) {
      if (req.url.includes(domain)) {
        whiteListed = true;
        break;
      }
    }

    return whiteListed;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const getAnonimusUrlRegexp = /get-anonymous-token/;
    if (getAnonimusUrlRegexp.test(req.url)) {
      return next.handle(req);
    }

    return this.auth.isReady.pipe(
      filter((isReady: boolean) => {
        return isReady === true;
      }),
      mergeMap(() => {
        if (!this.auth.isTokenValidByTime) {
          return this.checkAuthorisation(
            new HttpErrorResponse({}),
            ERROR_REASON.TOKENREFRESH,
          );
        }

        const isAuthenticated = req.context.get(AUTHENTICATED);

        if (!isAuthenticated || !this.isDomainInWhiteLists(req)) {
          return next.handle(req);
        }

        return next.handle(this.getAuthRequest(req)).pipe(
          catchError((error) => {
            const isHttpError = error instanceof HttpErrorResponse;

            if (
              !this.auth.isTokenValidByTime || (
                error.status === HttpStatusCode.Unauthorized &&
                getReason(error) === ERROR_REASON.TOKENREFRESH
              )
            ) {
              return this.checkAuthorisation(
                error,
                ERROR_REASON.TOKENREFRESH,
              ).pipe(
                switchMap(() => {
                  return next.handle(this.getAuthRequest(req)).pipe(
                    catchError((error) => {
                      console.error(error, 'will reload the page');
                      location.reload();
                      return of(error);
                    }),
                  );
                }),
              );
            }

            if (!isHttpError) {
              return throwError(error);
            }

            return throwError(error);
          }),
        );
      }),
    );
  }
}
