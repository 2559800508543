import { landingPageContentDe, landingPageContentEn } from './ecoreps/landing-page-content';
import { offersPageContent } from './ecoreps/offer-page-content';
import { footerContent } from './ecoreps/footer';

export const pagesConfig = {
  de: {
    ecoreps: {
      landingPage: landingPageContentDe,
      offerPage: offersPageContent,
      footer: footerContent,
    },
  },
  en: {
    ecoreps: {
      landingPage: landingPageContentEn,
      offerPage: offersPageContent,
      footer: footerContent,
    },
  },
};
