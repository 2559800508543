<div class="part footer">
	<div class="content">
		<div class="links">
			<a routerLink="/offers">{{ 'public.pages.offers.name' | translate }}</a>
			<a routerLink="/contact">{{ 'public.pages.contact.name' | translate }}</a>
			<a routerLink="/imprint">{{ 'public.pages.imprint.name' | translate }}</a>
			<a routerLink="/privacy-policy">{{ 'public.pages.privacy-policy.name' | translate }}</a>
		</div>
		<div class="copyright">
			&copy; {{year}}
			<span routerLink="/home">{{ content.copyright }}</span>
		</div>
	</div>
</div>
