// feature-flag.service.ts
import { inject, Injectable } from '@angular/core';
import { HttpService } from '@core/http';
import { BehaviorSubject, fromEvent, of } from 'rxjs';
import { WindowRefService } from '@services/window-ref.service';
import { DateTime, DurationLike } from 'luxon';
import { catchError } from 'rxjs/operators';

interface IBuildInfo {
  timestamp: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  static readonly RepeatNotificationDifference: DurationLike = { minute: 60 };

  private updateDetected$ = new BehaviorSubject(false);
  private initialBuildInfo: IBuildInfo = null;
  private notifiedAt: DateTime = null;
  private interval = null;
  private httpClient: HttpService<void> = inject(HttpService);
  private windowRefService: WindowRefService = inject(WindowRefService);

  public get hasUpdate() {
    return this.updateDetected$.asObservable();
  }

  public startInterval() {
    const interval = 60 * 1000;
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(() => {
      this.performCheck();
    }, interval);
  }

  public stopInterval() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  public onInit() {
    fromEvent(this.windowRefService.nativeWindow, 'focus').subscribe(() => {
      this.startInterval();
    });

    fromEvent(this.windowRefService.nativeWindow, 'blur').subscribe(() => {
      this.stopInterval();
    });

    this.performCheck();
  }

  private performCheck() {
    this.httpClient.get<IBuildInfo>(
      `/build.json?t=${DateTime.now()}`,  // always skip browser cache by adding a random parameter
      undefined,
      () => true,
    ).pipe(
      catchError((e) => {
        console.warn('Could not perform update check: Error fetching', e);
        return of(this.initialBuildInfo);
      }),
    ).subscribe(
      (buildInfo) => {
        if (!buildInfo?.timestamp) {
          console.warn('Could not perform update check: No info provided');
          return;
        }
        const newTimestamp = DateTime.fromISO(buildInfo.timestamp);
        if (!newTimestamp.isValid) {
          console.warn('Could not perform update check: Provided timestamp invalid');
          return;
        }
        if (!this.initialBuildInfo) {
          this.initialBuildInfo = buildInfo;
        }
        const initialTimestamp = DateTime.fromISO(this.initialBuildInfo.timestamp);
        if (initialTimestamp < newTimestamp) {
          let performNotification = true;
            if (
              this.notifiedAt &&
              this.notifiedAt.plus(AppUpdateService.RepeatNotificationDifference) > DateTime.now()
            ) {
              performNotification = false;
            }

          if (performNotification) {
            this.updateDetected$.next(true);
            this.notifiedAt = DateTime.now();
          }
        }
      },
    );
  }


}
