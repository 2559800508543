import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

const SKINS_TO_CLASSNAMES = {
  default: 'app-accordion',
  embed: 'app-accordion app-accordion_embed',
  embedHeadingLg: 'app-accordion app-accordion_embed_lg',
};

@Component({
    selector: 'app-accordion',
    template: `
    <div
      [class]="rootChildClassName"
      (window:resize)="onResize()"
      [class.app-accordion_open]="isOpen | async"
      #component
    >
      <div class="app-accordion__head {{headClass}}" (click)="toggle()" [class.has-icon]="icon">
        <img class="app-accordion__icon" [src]="icon" *ngIf="icon" />
        <div class="app-accordion__heading">{{ title }}</div>
        <div class="app-accordion__toggle" [ngClass]="toggleClass"></div>
      </div>
      <div class="app-accordion__body" #content>
        <div class="app-accordion__content">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
    styleUrls: ['./accordion.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class AccordionComponent implements OnInit, AfterViewInit {
  @Input() skin: 'default' | 'embed' = 'default';
  @Input() title = '';
  @Input() icon = '';
  @Input() toggleClass = 'plus';
  @Input() grow = true;
  @Input() headClass = '';
  @Output() public onToggle = new EventEmitter<boolean>();

  @ViewChild('content') body: ElementRef;
  @ViewChild('component') component: ElementRef;

  public isOpen = new BehaviorSubject<boolean>(false);

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public get rootChildClassName() {
    return SKINS_TO_CLASSNAMES[this.skin];
  }

  public toggle(): void {
    if (this.isOpen.getValue()) {
      this.close();
    } else {
      this.open();
    }
    this.onToggle.emit(this.isOpen.getValue());
  }

  public close() {
    this.isOpen.next(false);
    this.updateHeight();
    this.changeDetectorRef.detectChanges();
  }

  public open() {
    this.isOpen.next(true);
    this.updateHeight();
    this.changeDetectorRef.detectChanges();
  }

  public onResize(): void {
    this.updateHeight();
  }

  public updateHeight(): void {
    if (this.grow) {
      this.component?.nativeElement.style.setProperty(
        '--height',
        this.body.nativeElement.scrollHeight + 'px',
      );
    } else {
      this.component?.nativeElement.style.setProperty('--height', 'unset');
    }
  }

  ngOnInit(): void {
    this.document.fonts.ready.then(() => {
      this.updateHeight();
    });
  }

  ngAfterViewInit(): void {
    this.updateHeight();
  }
}
