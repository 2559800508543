import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { PermissionService } from '@core/auth/permission.service';
import IComment from '@models/comment';

@Component({
    selector: 'app-comment-text',
    templateUrl: './comment-text.component.html',
    styleUrls: ['./comment-text.component.scss'],
    standalone: false,
})
export class CommentTextComponent implements OnChanges {
  @Input() commentToEdit: IComment;
  @Input() comment: IComment;
  @Input() editMode = false;
  @Input() title: string;

  @Output() onEdit = new EventEmitter<IComment>();
  @Output() onSend = new EventEmitter<IComment>();
  @Output() onRemove = new EventEmitter<string>();

  @ViewChild('commentText') commentText: ElementRef;
  @ViewChild('commentTextArea') commentTextArea: ElementRef;

  constructor(private permissionService: PermissionService) {}

  get commentTextareaHight() {
    return `${this.commentText.nativeElement.offsetHeight + 80}px`;
  }

  get deleteCommentAllowed$() {
    return this.permissionService.isAdminRole$;
  }

  get editCommentAllowed$() {
    return this.permissionService.isAdminRole$;
  }

  ngOnChanges(c) {
    if (c.commentToEdit) {
      const { currentValue } = c.commentToEdit;
      if (currentValue?.id === this.comment?.id) {
        this.adjustHeight();
        this.setFocus();
      }
    }
  }

  toggleEdit() {
    this.onEdit.emit(this.comment);
  }

  send() {
    this.onSend.emit(this.comment);
  }

  remove() {
    this.onRemove.emit(this.comment._id);
  }

  private adjustHeight() {
    this.commentTextArea.nativeElement.style.height = this.commentTextareaHight;
  }

  private setFocus() {
    setTimeout(() => {
      this.commentTextArea.nativeElement.focus();
    }, 0);
  }
}
