import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import BasicRxComponent from '@components/BasicRxComponent';
import { IMediaItem } from '@models/media';
import { MediaType } from '@services/media-capture';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-media-content',
    templateUrl: './media-content.component.html',
    styleUrl: './media-content.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class MediaContentComponent
  extends BasicRxComponent
  implements AfterViewInit
{
  @Input() media: IMediaItem;

  @ViewChild('templateVideoContent') videoTemplate!: TemplateRef<unknown>;
  @ViewChild('templateAudioContent') audioTemplate!: TemplateRef<unknown>;

  currentTemplate!: TemplateRef<unknown>;
  isVideoExpand: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  async ngAfterViewInit(): Promise<void> {
    if (this.media) {
      this.setTemplate();
    }
  }

  setTemplate() {
    switch (this.media.type) {
      case MediaType.VIDEO:
        this.currentTemplate = this.videoTemplate;
        break;
      case MediaType.AUDIO:
        this.currentTemplate = this.audioTemplate;
        break;
      default:
        throw Error('Media type unknown');
    }

    this.cdr.markForCheck();
  }

  toggleVideoPlayer() {
    this.isVideoExpand.next(!this.isVideoExpand.getValue());
    this.cdr.markForCheck();
  }
}
