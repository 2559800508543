import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: false,
})
export class ProgressBarComponent {
  @Input() public progress: number;
  @Input() public average: number;
  @Input() public cut: number;
  @Input() public class: string;
}
