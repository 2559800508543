import { environment } from '@environments/environment';

export const convertQueryParamsToString = <T>(queryParams: T): string => {
  return Object.keys(queryParams).reduce((str, key, i) => {
    if (i === 0) {
      return `${key}=${queryParams[key]}`;
    }
    return `${str}&${key}=${queryParams[key]}`;
  }, '');
};

export const createProfileUrl = (...segments: string[]): string => {
  return [environment.API_URL_PROFILE, 'profile', ...segments].join('/');
};

export const createIngestionUrl = (...segments: string[]): string | null => {
  return [environment.API_INGESTION, 'ingestion', ...segments].join('/');
};

export const createNotificationUrl = (...segments: string[]): string => {
  return [environment.API_NOTIFICATION, 'notification', 'public', ...segments].join('/');
};

export const createContentUrl = (...segments: string[]): string => {
  return [environment.API_CONTENT, 'content', ...segments].join('/');
};

export const createChatbotUrl = (...segments: string[]): string => {
  return [environment.API_CHATBOT, 'chatbot', ...segments].join('/');
};

export const createMediaUrl = (...segments: string[]): string => {
  return [environment.API_MEDIA, 'media', ...segments].join('/');
};

export const createAggregationUrl = (...segments: string[]): string => {
  return [environment.API_AGGREGATION, 'aggregation', ...segments].join('/');
};
