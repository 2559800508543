<div id="page-container" [class]="(cssClass | async)">
  <topbar *ngIf="(topBar | async).show"></topbar>
  <div class="app-content" [class.with-sidebar]="(sideBar | async).show">
    <sidebar *ngIf="(sideBar | async).show"></sidebar>
    <router-outlet></router-outlet>
  </div>
  <footer-component *ngIf="(topBar | async).show"></footer-component>
  <div id="modalContainer" class="modal-container"></div>
</div>

<app-dialog
  #sessionExpiredModal
  [id]="modalService.MODAL_IDS.SESSION_EXPIRED"
  [closable]="false"
  [title]="'app.user.sessions-expired' | translate"
  [submitText]="'app.user.sessions-expired-login-button' | translate"
  [cancelText]="'app.user.sessions-expired-cancel-button' | translate"
  (onSubmit)="reLogin()"
  (onCancel)="logout()"
>
  <div [innerHTML]="'app.user.sessions-expired-modal-text' | translate"></div>
</app-dialog>

<app-dialog
  #userBlockedModal
  [id]="modalService.MODAL_IDS.DYNAMIC_MODAL"
  [closable]="false"
  [submittable]="false"
  [cancelable]="false"
  [title]="'app.user.blocked-modal-title' | translate"
>
  <p *ngIf="renderBlockReason()">
    {{ 'app.user.blocked-modal-text' | translate }}<br>
    <strong>{{ renderBlockReason() | translate }}</strong>
  </p>
  <p [innerHTML]="'app.user.blocked-modal-contact' | translate"></p>
</app-dialog>

<app-dialog
  #frontendUpdateModal
  [id]="modalService.MODAL_IDS.DYNAMIC_MODAL"
  [closable]="true"
  [submittable]="true"
  [cancelable]="true"
  [title]="'app.common.update-info-modal-title' | translate"
  [cancelText]="'app.common.update-info-modal-cancel-button' | translate"
  [submitText]="'app.common.update-info-modal-submit-button' | translate"
  (onSubmit)="reloadPage()"
  (onClose)="closeFrontendUpdateModal()"
  (onCancel)="closeFrontendUpdateModal()"
>
  <p [innerHTML]="'app.common.update-info-modal-text' | translate"></p>
</app-dialog>

<div [class]="dontwaste"></div>
