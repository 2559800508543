import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { ElementLoaderComponent } from './components/element-loader/element-loader.component';
import { ContainerLoaderComponent } from './components/container-loader/container-loader.component';
import { TranslatePipe } from '@ngx-translate/core';
@NgModule({
  declarations: [
    PageLoaderComponent,
    LoaderComponent,
    ElementLoaderComponent,
    ContainerLoaderComponent,
  ],
  imports: [CommonModule, TranslatePipe],
  exports: [
    LoaderComponent,
    PageLoaderComponent,
    ElementLoaderComponent,
    ContainerLoaderComponent,
  ],
})
export class LoaderModule {}
