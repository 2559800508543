import { ProgressState } from './profile';


export interface ISectionUserdata {
  sectionId: string;
  bookmarkedAt?: Date;
  progress?: {
    state: ProgressState;
  };
  note?: string;
}

export interface IChapterUserdata {
  chapterId: string;
  bookmarkedAt?: Date;
  progress?: {
    state: ProgressState;
  };
  sections?: Record<string, ISectionUserdata>;
  note?: string;
}

export interface IColumnUserdata {
  columnId: string;
  progress?: {
    processed: number;
    understood: number;
  };
  chapters: Record<string, IChapterUserdata>;
  timeTracking?: {
    sessions: ITimeTrackingSession[];
    archive?: ITimeTrackingSession;
    minutes: number;
  };
}


export enum DisallowSelfReactivationState {
  None = 0,
  All = 1,
}

export interface ICourseUserdata {
  _id: string;
  userId: string;
  courseId: string;
  createdAt?: Date;
  updatedAt?: Date;
  disallowSelfReactivation?: DisallowSelfReactivationState;
  progress?: {
    processed: number;
    understood: number;
  };
  columns: Record<string, IColumnUserdata>;
}

export interface ITimeTrackingSession {
  from: string;
  until: string;
  minutes: number;
}
