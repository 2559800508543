<ng-container *ngIf="!compact">
	<div [class]="'progress-buttons ' + style" [ngClass]="{
	  'is-updating': isUpdating,
	}">
		<span class="title"> <ng-content select=".title"></ng-content></span>
		<button
			class="progress-option"
			(click)="emitFullyUnderstood($event);"
			[ngClass]="{checked: isFullyUnderstood || isObservable && (progressState | async) == ProgressState.fullyUnderstood }"
			[class]="additionalButtonClass"
      [disabled]="isUpdating"
		>
      {{ 'app.common.progress-button-fully-understood' | translate }}
			<span class="infoPopover" *ngIf="isAnonymous">
        {{ 'app.user.register-to-view' | translate }}
      </span>
		</button>

		<button
			class="progress-option partly-understood"
			(click)="emitPartlyUnderstood($event)"
			[ngClass]="{checked: isPartlyUnderstood || isObservable && (progressState | async) == ProgressState.partlyUnderstood }"
			[class]="additionalButtonClass"
      [disabled]="isUpdating"
		>
      {{ 'app.common.progress-button-partly-understood' | translate }}
			<span *ngIf="isAnonymous" class="infoPopover">
        {{ 'app.user.register-to-view' | translate }}
      </span>
		</button>

		<button
			class="progress-option not-understood"
			(click)="emitNotUnderstood($event)"
			[ngClass]="{checked: isNotUnderstood || isObservable && (progressState | async) == ProgressState.notUnderstood }"
			[class]="additionalButtonClass"
      [disabled]="isUpdating"
		>
			{{ 'app.common.progress-button-not-understood' | translate }}
			<span class="infoPopover" *ngIf="isAnonymous">
        {{ 'app.user.register-to-view' | translate }}
      </span
			>
		</button>
	</div>
</ng-container>

<ng-container *ngIf="compact">
	<button
		class="progress-option fully-understood compact"
		(click)="emitFullyUnderstood($event)"
		[ngClass]="{
      checked: isFullyUnderstood || isObservable && (progressState | async) == ProgressState.fullyUnderstood,
      'is-updating': isUpdating,
		}"
		[class]="additionalButtonClass"
    [disabled]="isUpdating"
	></button>
	<button
		class="progress-option partly-understood compact"
		(click)="emitPartlyUnderstood($event)"
		[class]="additionalButtonClass"
		[ngClass]="{
      checked: isPartlyUnderstood || isObservable && (progressState | async) == ProgressState.partlyUnderstood,
      'is-updating': isUpdating,
    }"
    [disabled]="isUpdating"
	></button>
	<button
		class="progress-option not-understood compact"
		(click)="emitNotUnderstood($event)"
		[class]="additionalButtonClass"
		[ngClass]="{
		  checked: isNotUnderstood || isObservable && (progressState | async) == ProgressState.notUnderstood,
      'is-updating': isUpdating,
		}"
    [disabled]="isUpdating"
	></button>
</ng-container>
