import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    standalone: false,
})
export class LoaderComponent {}
