import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { VideoPlayerCustomControlsComponent } from './components/video-player-custom-controls/video-player-custom-controls.component';
import { MatIconModule } from '@angular/material/icon';
import { VideoPlayerService } from './video-player.service';

@NgModule({
  declarations: [
    VideoPlayerComponent,
    VideoPlayerCustomControlsComponent,
  ],
  providers: [VideoPlayerService],
  imports: [CommonModule, MatIconModule],
  exports: [VideoPlayerComponent, VideoPlayerCustomControlsComponent],
})
export class VideoPlayerModule {}
