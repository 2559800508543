/**
 * Created by huck on 15.06.18.
 */

import { IColumn } from './course';
import ISection, { ChatbotDisabledBy } from './section';
import { IExam } from './exam';
import { ChapterIndex } from '../utils/chapterUtils';
import IComment from './comment';

//import {ContentType} from "@angular/http/src/enums";

export enum ChapterDisplayMode {
  hierachical = 0,

  // These two (1 & 2) are deprecated, but stay here for db compatibility
  includeChildren = 1,
  includedInParent = 2
}

export enum ChapterType {
  summaryChapter = 0,
  exam = 1,
  examAssignment = 2,
  examByTopicChapter = 3,
  additionalTasks = 4
}

export enum PreviewMode {
  closedMode = 0,
  membersMode = 1,
  publicMode = 2
}

export enum ProgressType {
  none = 0,
  byChapter = 1,
  bySection = 2
}

export enum ChapterPriority {
  normal = 0,
  highlighted = 1
}

export class ChapterTypeInfo {
  constructor(public column: IColumn) {}

  public get hasExamSectionTypes(): boolean {
    return this.column.chapter_style !== 'summary';
  }

  public get progressType(): ProgressType {
    return this.column.progress_tracking;
  }

  public get defaultDisplayMode(): ChapterDisplayMode {
    if (this.forcedDisplayMode) {
      return this.forcedDisplayMode;
    }
    return ChapterDisplayMode.hierachical;
  }

  public get showChapterTitle(): boolean {
    return this.column.show_chapter_title;
  }

  public get forcedDisplayMode(): ChapterDisplayMode {
    return this.column.enforce_hierarchical_chapter_structure
      ? ChapterDisplayMode.hierachical
      : null;
  }

  public get showChapterIndizes(): boolean {
    return this.column.show_chapter_indices;
  }

  public get canHaveSections(): boolean {
    return true;
  }

  public get localName(): string {
    return this.column.title;
  }
}

export interface IChapter {
  _id: string;
  columnId: string;
  courseId: string;
  parentChapterId?: string;
  title: string;
  fullTitle?: string;
  subchapters?: IChapter[];
  deleted?: boolean;
  chapterId?: string;
  assignmentIds?: string[];
  examAssignments?: IChapterExamAssignment[];
  active?: boolean;
  sections?: ISection[];
  index?: ChapterIndex;
  displayMode?: ChapterDisplayMode;
  priority?: ChapterPriority;
  url_slug?: string;
  containsDisplay?: string[];
  previous?: string;
  next?: string;
  path?: string;
  inPreview?: boolean;
  pathArray?: string[];
  hasSections?: boolean;
  orderString?: string;
  subChapterCount: number;
  previewMode: PreviewMode;
  comments?: [IComment];
  chatbotDisabledBy: ChatbotDisabledBy;
  createdAt: string;
  updatedAt: string;
  stats?: {
    commentCount: number;
  };
}

export interface IDuplicateChapterModel {
  _id: string;
}

export interface ICreateChapterModel {
  title: string;
  columnId: string;
  parentChapterId?: string;
  afterChapterId?: string;
}
export interface IUpdateChapterModel {
  _id: string;
  title: string;
  displayMode: ChapterDisplayMode;
  priority: ChapterPriority;
  inPreview?: boolean;
  previewMode: PreviewMode;
  chatbotDisabledBy: ChatbotDisabledBy;
}

export interface IMoveChapterModel {
  parentId: string;
  index: number;
  chapterId?: string;
}

export interface IChapterExamAssignment {
  examId: string;
  assignmentId: number;
  courseId: string;
}

export interface IAssignments {
  assignments: IAssignment[];
}

export interface IAssignment {
  assignmentId: number;
  content: string;
  solution?: string;
  video?: string;
  showSolution?: boolean;
  showVideo?: boolean;
}

export interface IExams {
  exams: IExam[];
}

export interface IFullChapter extends IChapter {
  sections: [ISection];
}
