import {
  IMediaGetter,
  IUploadError,
  IUploadResult,
  UploadResult,
} from './types';
import { VideoService } from './video.service';
import { BehaviorSubject, Subject, from } from 'rxjs';
import { IMediaRecorder, RecordStatus } from './types';
import { HttpService } from '@core/http';

const NEW_TAB_HTML = `
<html>
  <body>
    <script>
      (async function() {
        function stopAllTracks(stream) {
          stream.getTracks().forEach(track => track.stop());
        }

        try {
          const videoStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
          const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
          const combinedStream = new MediaStream([...videoStream.getTracks(), ...audioStream.getTracks()]);

          const mimeTypes = [
            'video/webm; codecs=vp9,opus',
            'video/webm; codecs=vp8,opus',
            'video/webm; codecs=vp8',
            'video/webm',
            'video/mp4'
          ];

          let options = null;
          for (const mimeType of mimeTypes) {
            if (MediaRecorder.isTypeSupported(mimeType)) {
              options = { mimeType };
              break;
            }
          }

          if (!options) {
            console.error('No supported MIME type found');
            throw new Error('Unsupported MIME type');
          }

          const mediaRecorder = new MediaRecorder(combinedStream, options);

            window.addEventListener('message', (event) => {
              if (event.origin !== window.location.origin) {
                  return;
              }

              const data = event.data;
              switch (data.command) {
                case 'start':
                    mediaRecorder.start();
                    break;
                case 'stop':
                    mediaRecorder.stop();
                    break;
                case 'pause':
                    if (mediaRecorder.state === "recording") {
                        mediaRecorder.pause();
                    }
                    break;
                case 'resume':
                    if (mediaRecorder.state === "paused") {
                        mediaRecorder.resume();
                    }
                    break;
              }
          });

          let chunks = [];
          mediaRecorder.ondataavailable = (e) => chunks.push(e.data);

          mediaRecorder.onstop = () => {
            const blob = new Blob(chunks, { type: 'video/webm' });
            stopAllTracks(combinedStream);
            if (blob.size === 0) {
              console.error('Blob is empty');
            } else {
              setTimeout(() => {
                window.opener.postMessage({ blob: blob, isRecord: true }, '*');
                window.close();
              }, 500);
            }
          };

          videoStream.getVideoTracks().forEach(track => {
            track.addEventListener('ended', () => {
              mediaRecorder.stop();
            });
          });

          mediaRecorder.start();
          setTimeout(() => mediaRecorder.stop(), 600000); // limit for recording time 10 minutes

        } catch (error) {
          window.opener.postMessage({ error: error, isRecord: true }, '*');
          console.error('Error capturing media:', error);
        }
      })();
    </script>
  </body>
</html>
`;

export class VideoCapturer implements IMediaRecorder, IMediaGetter {
  public uploadResult$ = new Subject<UploadResult>();
  public recordStatus$ = new BehaviorSubject<RecordStatus>('none');
  public error$ = new Subject<Error | null>();
  public mediaRecorder: MediaRecorder = null;
  private tabContext: Window;
  private vimeoService;

  constructor(private httpService?: HttpService<IUploadResult>) {
    this.vimeoService = new VideoService(httpService);
  }

  public async startRecord(): Promise<Subject<UploadResult>> {
    await this.captureAndDownloadInNewTab();
    return this.uploadResult$;
  }

  public handleReceivedMediaBlob(blob: Blob | undefined) {
    if (blob?.size > 0) {
      this.recordStatus$.next('upload');
      const subscription = from(this.vimeoService.upload(blob)).subscribe(
        (result: IUploadResult) => {
          this.uploadResult$.next(result);
          this.recordStatus$.next('success');
        },
        (err: IUploadError) => {
          this.uploadResult$.next(err);
          this.recordStatus$.next('error');
        },
        () => {
          subscription.unsubscribe();
        },
      );
    } else {
      this.setError(new Error('Received blob is empty'));
    }
  }

  public stopRecord() {
    this.tabContext.postMessage({ command: 'stop' }, '*');
  }

  public getMedia(id: string) {
    return this.vimeoService.getMedia(id);
  }

  private async captureAndDownloadInNewTab(): Promise<void> {
    this.recordStatus$.next('inprogress');

    const newTab = window.open('about:blank', '_blank');
    if (!newTab) {
      console.error('Could not open new tab');
      this.recordStatus$.next('error');
      return;
    }

    newTab.document.write(NEW_TAB_HTML);
    newTab.document.close();

    const handler = (event: {
      origin: string;
      data: {
        error?: Error;
        isRecord: boolean;
        blob?: Blob;
      };
    }) => {
      const skipEvent =
        event.origin !== window.location.origin || !event.data?.isRecord;

      if (skipEvent) {
        return; // Safety check to ensure same origin and filter other global events from browser extension that works in parallel and send event such as react/angular devtools, other
      }
      const { error, blob } = event.data;

      if (error) {
        this.setHandledErrorIfExists(error);
        window.removeEventListener('message', handler);
        return;
      }

      this.handleReceivedMediaBlob(blob);
      window.removeEventListener('message', handler);
    };

    window.addEventListener('message', handler);

    this.tabContext = newTab;
  }

  private setError(error: Error) {
    this.recordStatus$.next('error');
    this.error$.next(error);
    this.stopRecord();
  }

  private setHandledErrorIfExists(error: Error) {
    if (error) {
      let _error = error;

      // @ts-expect-error code is always exists but ts throw an error
      if (error.code === 0 || error.message === 'Permission denied') {
        _error = new Error(
          'Zugriff verweigert, bitte erlauben Sie den Zugriff auf Mikrofon und Bildschirmaufnahme.',
        );
      }
      this.setError(_error);
      console.error(_error);
    }
  }
}
