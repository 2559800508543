import { Component, Input } from '@angular/core';
import { ChatbotDisabledBy, DataQualityReason } from '@models/section';
import { NgClass, NgIf } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-chatbot-disabled-reason',
    templateUrl: './chatbot-disabled-reason.component.html',
    styleUrls: ['./chatbot-disabled-reason.component.scss'],
    imports: [
        MatTooltip,
        NgIf,
    ],
})
export class ChatbotDisabledReasonComponent {
  @Input({ required: true }) public chatbotDisabledBy: ChatbotDisabledBy = null;
  @Input({ required: true }) public dataQualityReason: DataQualityReason = null;
  public readonly ChatbotDisabledBy = ChatbotDisabledBy;
  public readonly DataQualityReason = DataQualityReason;
}
