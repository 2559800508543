import IProfile from '@models/profile';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';

export interface IUserProfile extends IProfile {
  _id: string;
  user_id: string;
  roles?: string[];
  email: string;
  picture: string;
  last_ip: string;
  logins_count: number;
  forcedLogouts: number[];
  blocks: IUserBlock[];
}

export enum BlockReason {
  UnpaidOrder = 'unpaid-order',
  FaultyEmailAddress = 'faulty-email-address',
  Generic = 'generic',
}

export interface IUserBlock {
  from: string;
  until?: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  reason: BlockReason;
  message?: string;
}

export interface IUserProfileUpdateRequest {
  _id: string;
  payload: Partial<IUserProfile>;
}

export interface ICommentsTrackingComment {
  createdAt: string;
  updatedAt: string;
  length: number;
  parentId: string;
  media: object[];
  timestamp?: DateTime;
}

export interface ICommentsTracking {
  user_id: string;
  comments: ICommentsTrackingComment[];
}

export type CommentStatType = 'all' | 'previous-previous-previous-month' | 'previous-previous-month' | 'previous-month' | 'this-month';

export interface ICommentsTrackingViewItem extends ICommentsTracking {
  identification: string;
  expanded$: BehaviorSubject<boolean>;
  commentStats: Record<CommentStatType, ICommentsTrackingComment[]>;
}

