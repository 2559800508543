import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { LoginService } from '@core/auth/login.service';
import { DisposeBag } from '@utils/DisposeBag';
import { BehaviorSubject, fromEvent, merge } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { LocalStorageService } from './local-storage';
import { WindowRefService } from './window-ref.service';
import { HttpService } from '@core/http';
import { createProfileUrl } from '@utils/index';

const EVENTS_DELAY = 400;
const SESSION_CHECK_INTERVAL = 60000;
const ACTIVITY_INTERVAL = '__st__';

@Injectable({
  providedIn: 'root',
})
export class SessionActivityService {
  private sessionInterval: NodeJS.Timeout;
  private bag: DisposeBag = new DisposeBag();
  public suspended = new BehaviorSubject(false);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private localStorageService: LocalStorageService,
    private loginService: LoginService,
    private windowRefService: WindowRefService,
    private client: HttpService<void>,
  ) {}

  public onInit() {
    fromEvent(this.windowRefService.nativeWindow, 'blur').subscribe(() => {
      this.clearAllSubscription();
    });

    fromEvent(this.windowRefService.nativeWindow, 'focus').subscribe(() => {
      this.subscribeToLogin();
    });

    this.subscribeToLogin();
  }

  public onDestroy() {
    this.clearAllSubscription();
    this.clearStorage();
  }

  private subscribeToLogin() {
    this.bag.dispose();
    this.bag.add(
      this.loginService.isLoggedIn.pipe(filter(Boolean)).subscribe(() => {
        this.sendRequestOnInitIfNeed();
        this.subcsribeToUserEvents();
        this.startCheckSessionInterval();
      }),
    );
  }

  private get isActivityInATimeRange(): boolean {
    const expireInInterval = this.localStorageService.getItem(ACTIVITY_INTERVAL);
    if (!expireInInterval) {
      return false;
    }
    return Date.now() - Number(expireInInterval) <= this.requestTimeRange;
  }

  private get requestTimeRange() {
    return SESSION_CHECK_INTERVAL - EVENTS_DELAY;
  }

  private subcsribeToUserEvents() {
    this.bag.add(
      merge(
        fromEvent(this.document, 'click'),
        fromEvent(this.document, 'mousemove'),
        fromEvent(this.document, 'scroll'),
        fromEvent(this.document, 'keypress'),
      )
        .pipe(debounceTime(EVENTS_DELAY))
        .subscribe(() => {
          const sendTime = Date.now() + SESSION_CHECK_INTERVAL;
          this.localStorageService.setItem(ACTIVITY_INTERVAL, sendTime.toString());
        }),
    );
  }

  private startCheckSessionInterval() {
    clearInterval(this.sessionInterval);
    this.sessionInterval = setInterval(() => {
      this.sendRequestIfNeded();
    }, this.requestTimeRange);
  }

  private stopSessionInterval() {
    clearInterval(this.sessionInterval);
  }

  private sendRequestIfNeded() {
    if (this.isActivityInATimeRange && !this.suspended.getValue()) {
      this.sendRequest();
    }
  }

  private sendRequest() {
    if (this.suspended.getValue()) {
      return;
    }
    return this.client.get<boolean>(createProfileUrl('session')).toPromise();
  }

  private sendRequestOnInitIfNeed() {
    if (!this.isActivityInATimeRange) {
      this.sendRequest();
    }
  }

  private clearAllSubscription() {
    this.stopSessionInterval();
    this.bag.dispose();
  }

  private clearStorage() {
    this.localStorageService.removeItem(ACTIVITY_INTERVAL);
  }
}
