import ILandingPageContent from '../../models/landing-page/lp-content.model';

export const landingPageContentDe: ILandingPageContent = {
  welcomeContent: {
    headingPart1: 'Intensive Online-',
    headingPart2: 'Kurse für',
    headingAccent: 'deine Uni',
    preHeading: 'Erfolgreich durch dein Studium',
    smallerPreHeading: 'mit Offline- und Onlinerepetitorien für deine Uni',
    selectPlaceholder: 'Bitte wähle deine Universität',
  },
  introContent: {
    heading: 'Warum ecoreps Online-Kurse?',
    description:
      'Wir von ecoreps strukturieren deinen ganzen Uni-Stoff, erklären ihn verständlich und betreuen dich beim Lernen intensiv über Whatsapp.',
    columnHeading1: 'Übersichtliche Struktur',
    columnDescription1:
      'Deinen ganzen Stoff haben wir übersichtlich strukturiert und einfach und verständlich erklärt.',
    columnHeading2: 'Whatsapp-Service',
    columnDescription2:
      'Wir betreuen dich die ganze Zeit über Whatsapp, bis du deine Prüfungen schreibst.',
    columnHeading3: 'Besteh-Garantie',
    columnDescription3:
      'Für die meisten Kursen gilt: Falls du deine Prüfung nicht bestehst, kannst du deinen Kurs kostenlos wiederholen - bis du bestehst.',
  },
  faqs: [
    {
      title: 'Was bedeutet Uni-Spezifisch, an was habt ihr euch orientiert?',
      text: 'Wir schauen uns das gesamte Material von dem Prof an, bei dem ihr eure Klausur schreiben werdet (Vorlesungsunterlagen, Übungsblätter, alte Klausuren usw.). Unseren Kurs bauen wir dann so auf, dass er euch perfekt auf die Klausur bei diesem Prof vorbereitet.',
    },
    {
      title: 'Wie schnell kann ich auf WhatsApp mit Hilfe rechnen?',
      text: 'Wir bemühen uns, euch möglichst schnell zu helfen. Dabei achten wir darauf, dass wir euch umso schneller helfen, je näher der Klausur-Termin rückt.',
    },
    {
      title: 'An wen kann ich mich wenden, wenn ich etwas nicht verstehe?',
      text: 'In jedem Kurs steht eine Nummer, über die du die zuständige Ansprechpartner*in für das jeweilige Fach auf WhatsApp erreichst :)',
    },
    {
      title: 'Wie viel Zeit spart mir ein ecoreps Kurs?',
      text: 'Das ist natürlich je nach Fach und Lerntyp unterschiedlich. Da wir aber alle wichtigen Materialien für dich zusammengefasst haben und ausführliche und verständliche Lösungen zu Übungen und Altklausuren für dich erstellt haben, sparst du auf jeden Fall viele Stunden Lernzeit (wir schätzen zwischen 30 und 100 Stunden)',
    },
    {
      title:
        'Muss ich die Vorlesung noch schauen oder reicht der ecoreps-Kurs?',
      text: 'Viele unserer Teilnehmer*innen lernen ausschließlich mit unseren Kursen und verzichten auf die Vorlesungen der Uni und erzielen damit sehr gute Noten in ihren Klausuren. Ob man auf die Vorlesung wirklich verzichten sollte, hängt aber natürlich stark vom eigenen Lerntyp, der verfügbaren Zeit und dem jeweiligen Prof ab.',
    },
    {
      title: 'Kann man die Kurse auch noch kurz vor der Klausur buchen?',
      text: 'Du kannst unsere Kurse jederzeit buchen: Viele unserer Teilnehmer*innen buchen die Kurse schon direkt zu Beginn des Semesters, aber viele auch erst in den letzten Wochen oder sogar Tagen vor der Klausur.',
    },
    {
      title: 'Kann ich einen Kurs testen, bevor ich ihn buche?',
      text: 'Ja :) Du kannst einfach auf „Kostenlos Testen“ klicken und dann deine Uni und den Kurs auswählen. Dort kannst du zu jedem Bereich des Kurses (Zusammenfassung, Übungslösungen, Altklausurlösungen usw.) die ersten Kapitel kostenlos anschauen. In manchen unserer Kurse sind sogar komplette Bereiche wie die Übungslösungen dauerhaft kostenlos verfügbar.',
    },
    {
      title: 'Welche Bezahl-Methoden bietet ihr an?',
      text: 'Du kannst unsere Kurse entweder direkt über Paypal bezahlen oder in den nächsten 14 Tagen per Überweisung (der Kurs wird trotzdem direkt freigeschaltet, du musst also nicht warten, bis die Überweisung bei uns angekommen ist).',
    },
  ],
  teamInfo: {
    title: 'Das sind Wir',
    part1:
      'Wir sind Edwin und Leo und haben 2013 während unseres Studiums ein Nachilfeunternehmen gegründet, mit dem wir\n' +
      'Studenten und Studentinnen auf ihre Mathe-, Statistik- und Wirtschafts-Klausuren vorbereiten. Mittlerweile betreuen wir pro\n' +
      'Studienjahr mehr als 2000 Studierende.',
    part2:
      'Wir haben beide selbst Wirtschaft studiert und halten einen Bachelor in Wirtschaftswissenschaften (Vertiefung:\n' +
      'Quantitative Ökonomik) und Master-Abschlüsse in Statistik (Leo) und Financial Economics (Edwin)',
  },
  whoElse: {
    titlePart1: 'Wer macht sonst',
    titlePart2: 'noch so mit',
    checks: [
      'Studierende und Absolvent*innen wirtschaftswissenschaftlicher und anderer quantitativer Studiengänge',
      'Alle hatten sehr gute Leistungen in den relevanten Fächern',
      'Sehr nett im Umgang mit Studierenden',
    ],
    star: 'Wirtschafts-Studium leicht gemacht',
  },
  reviews: {
    title: 'Das sagen unsere Studierenden',
  },
  attributes: [
    {
      icon: 'book',
      title: 'Material',
      description:
        'Unsere Kurse enthalten jeweils eine ausführliche Zusammenfassung und umfangreiches Übungsmaterial.',
    },
    {
      icon: 'tap',
      title: 'Uni-spezifisch',
      description:
        'Die Inhalte unserer Kurse sind genau an deine Uni und an die jeweiligen Professoren angepasst',
    },
    {
      icon: 'cycle',
      title: 'Immer aktuell',
      description:
        'Wir aktualisieren unsere Kurse jedes Semester, so bleiben sie immer aktuell.',
    },
    {
      icon: 'video',
      title: 'Videoerklärungen',
      description:
        'Unsere gesamten Zusammenfassungen erklären wir zusätzlich in kurzen Videos, die du immer wieder anschauen kannst.',
    },
    {
      icon: 'heart',
      title: 'leicht verständliche Erklärungen',
      description:
        'Wir haben darauf geachtet, dass wir alle Themen in leicht verständlicher Sprache erklären.',
    },
    {
      icon: 'bookmark',
      title: 'Ergebnisse',
      description:
        'Unsere Kursteilnehmer/innen bestehen in 93% der Fälle ihre Klausur und schreiben im Durchschnitt bessere Noten als der Semester-Durchschnitt.',
    },
  ],
};

export const landingPageContentEn: ILandingPageContent = {
    welcomeContent: {
      headingPart1: 'Intensive Online-',
      headingPart2: 'Courses for',
      headingAccent: 'your University',
      preHeading: 'Successfully through your studies',
      smallerPreHeading: 'with offline and online review courses for your university',
      selectPlaceholder: 'Please select your university',
    },
    introContent: {
      heading: 'Why ecoreps Online Courses?',
      description: 'At ecoreps, we structure your entire university material, explain it clearly, and support you intensively via WhatsApp.',
      columnHeading1: 'Clear Structure',
      columnDescription1: 'We have structured all your material clearly and explained it in a simple and understandable way.',
      columnHeading2: 'WhatsApp Support',
      columnDescription2: 'We support you via WhatsApp the entire time until you take your exams.',
      columnHeading3: 'Pass Guarantee',
      columnDescription3: 'For most courses: If you fail your exam, you can retake the course for free—until you pass.',
    },
    faqs: [
      {
        title: 'What does university-specific mean? What do you base it on?',
        text: 'We review all materials from the professor who will be giving your exam (lecture notes, exercise sheets, past exams, etc.). We then design our course to prepare you perfectly for that professor’s exam.',
      },
      {
        title: 'How quickly can I get help via WhatsApp?',
        text: 'We strive to help you as quickly as possible. The closer the exam date gets, the faster we respond.',
      },
      {
        title: 'Who can I contact if I don\'t understand something?',
        text: 'Each course includes a number where you can reach the responsible contact person for your subject via WhatsApp. :)',
      },
      {
        title: 'How much time can an ecoreps course save me?',
        text: 'This depends on the subject and your learning style. However, since we have summarized all important materials and created detailed, easy-to-understand solutions for exercises and past exams, you will definitely save many hours of study time (we estimate between 30 and 100 hours).',
      },
      {
        title: 'Do I still need to watch the lecture, or is the ecoreps course enough?',
        text: 'Many of our participants study exclusively with our courses, skip university lectures, and still achieve excellent exam results. Whether you should skip lectures depends on your learning style, available time, and your professor.',
      },
      {
        title: 'Can I book a course shortly before the exam?',
        text: 'You can book our courses anytime. Many of our participants book at the start of the semester, while others book in the last weeks or even days before the exam.',
      },
      {
        title: 'Can I test a course before purchasing it?',
        text: 'Yes :) Simply click on \'Try for Free,\' select your university and course, and view the first chapters of each course section (summary, exercise solutions, past exam solutions, etc.) for free. Some courses even have full sections, like exercise solutions, permanently available for free.',
      },
      {
        title: 'What payment methods do you offer?',
        text: 'You can pay for our courses directly via PayPal or within 14 days via bank transfer (the course is unlocked immediately, so you don’t have to wait for the payment to be processed).',
      },
    ],
    teamInfo: {
      title: 'This is Us',
      part1: 'We are Edwin and Leo, and in 2013, during our studies, we founded a tutoring company to help students prepare for their math, statistics, and economics exams. Today, we support more than 2,000 students per academic year.',
      part2: 'We both studied economics and hold a Bachelor\'s degree in Economics (specializing in Quantitative Economics) and Master’s degrees in Statistics (Leo) and Financial Economics (Edwin).',
    },
    whoElse: {
      titlePart1: 'Who else',
      titlePart2: 'is involved',
      checks: [
        'Students and graduates from economics and other quantitative degree programs',
        'All achieved excellent results in the relevant subjects',
        'Very friendly and supportive in working with students',
      ],
      star: 'Making economics studies easier',
    },
    reviews: {
      title: 'What our students say',
    },
    attributes: [
      {
        icon: 'book',
        title: 'Materials',
        description: 'Each of our courses includes a detailed summary and extensive practice material.',
      },
      {
        icon: 'tap',
        title: 'University-Specific',
        description: 'Our course content is specifically tailored to your university and professors.',
      },
      {
        icon: 'cycle',
        title: 'Always Up-to-Date',
        description: 'We update our courses every semester to ensure they stay relevant.',
      },
      {
        icon: 'video',
        title: 'Video Explanations',
        description: 'We provide short video explanations for all summaries, which you can rewatch anytime.',
      },
      {
        icon: 'heart',
        title: 'Easy-to-Understand Explanations',
        description: 'We ensure that all topics are explained in clear and simple language.',
      },
      {
        icon: 'bookmark',
        title: 'Results',
        description: '93% of our course participants pass their exams, and they achieve above-average grades.',
      },
    ],
  }
;
