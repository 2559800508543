import { Component, inject, Input, OnInit } from '@angular/core';
import { ButtonGroupComponent } from '@components/button-group/button-group.component';
import { TranslateService } from '@ngx-translate/core';
import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import BasicRxComponent from '@components/BasicRxComponent';
import { ProfileService } from '@services/profile.service';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from '@core/auth';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-language-toggle',
  standalone: true,
  imports: [
    ButtonGroupComponent,
    NgClass,
    NgForOf,
    AsyncPipe,
    NgIf,
  ],
  template: `
    <div class="language-toggle-component">
      <div *ngIf="label" class="language-toggle-component__intro-text" [innerHTML]="label">
      </div>
      <app-button-group>
        <button *ngFor="let code of languages" class="btn secondary" (click)="setLanguage(code)"
                [disabled]="isLoading$ | async"
                [ngClass]="{
                outline: translation.currentLang !== code,
                primary: translation.currentLang === code,
              }"
        >
          {{ code.toLocaleUpperCase() }}
        </button>
      </app-button-group>
    </div>
  `,
  styleUrl: 'language-toggle.component.scss',
})
export class LanguageToggleComponent extends BasicRxComponent implements OnInit {
  @Input() label: string;
  public translation = inject(TranslateService);
  public languages = this.translation.getLangs();
  public isLoading$ = new BehaviorSubject(false);

  private profileService = inject(ProfileService);
  private loginService = inject(LoginService);

  public setLanguage(code: string) {
    if (this.translation.currentLang === code) {
      return;
    }
    this.translation.use(code);
    if (this.loginService.isLoggedIn.getValue() && !this.loginService.isAnonymous.getValue()) {
      this.isLoading$.next(true);
      this.profileService.setLanguage(code).subscribe((profile) => {
        this.translation.use(profile.language);
        this.profileService.fetchProfile().subscribe(() => {
          this.profileService.setProfile(profile);
        });
      });
    }
  }

  ngOnInit(): void {
    this.bag.add(this.profileService.profile.subscribe((profile) => {
      this.isLoading$.next(false);
      if (
        profile && profile.language !== this.translation.currentLang
        && this.languages.includes(profile.language)
      ) {
        this.translation.use(profile.language);
      }
    }));
  }
}
