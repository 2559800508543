<div class="comment" [ngClass]="{expanded: showReplies}">
  <div class="content">
    <div class="text">
      <div class="user-comment">
        <app-comment-text
          [title]="'app.course.comment.question-title' | translate"
          [commentToEdit]="commentToEdit"
          [comment]="comment"
          [editMode]="editMode || (editCommentsAllowed$ | async)"
          (onEdit)="toggleEditableComment($event)"
          (onSend)="sendToEdit()"
          (onRemove)="deleteComment($event)"
        ></app-comment-text>
      </div>

      <div class="replies">
        <div class="reply" *ngFor="let reply of comment.replies">
          <app-comment-text
            [title]="'app.course.comment.reply-title' | translate: { author: responseAuthor(reply) }"
            [commentToEdit]="commentToEdit"
            [comment]="reply"
            [editMode]="editMode || (editCommentsAllowed$ | async)"
            (onEdit)="toggleEditableComment($event)"
            (onSend)="sendToEdit()"
            (onRemove)="deleteCommentReply(comment._id, reply._id)"
          ></app-comment-text>
        </div>
        <ng-container *ngIf="!editMode">
          <form
            [formGroup]="replyForm"
            *ngIf="isReplyingActivated$ | async"
            class="replyForm"
          >
            <div class="input input__re-replay">
              <textarea
                #commentsInput
                class="textarea"
                formControlName="text"
                (input)="autoGrow($event.target)"
                [placeholder]="'app.course.comment.reply-input-placeholder' | translate"
                (focus)="onInputFocus()"
                (blur)="onInputBlur()"
              >
              </textarea>
              <button class="btn outline success" (click)="submit($event)">
                <span class="fa fa-envelope"></span>
              </button>
              <ng-container *ngIf="isMediaCommentEnabled">
                <app-media-capture
                  *ngIf="editCommentsAllowed$ | async"
                  (recordSuccess)="mediaRecordSuccess($event)"
                  (recordError)="mediaRecordError($event)"
                  (recordStatusChange)="recordStatusChange($event)"
                />
              </ng-container>
            </div>
            <div class="help-block" *ngIf="submitted">
              <div
                *ngIf="f.text?.errors && f.text?.dirty"
                class="invalid-feedback d-block"
              >
                <div
                  *ngIf="f.text?.errors?.minlength"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ 'app.course.comment.input-error-too-short' | translate }}
                </div>
                <div
                  *ngIf="!f.text?.errors?.minlength && f.text?.errors?.required"
                  class="alert alert-danger"
                  role="alert"
                >
                  {{ 'app.course.comment.input-error-empty' | translate }}
                </div>
              </div>
            </div>
          </form>
          <div
            *ngIf="!(isReplyingActivated$ | async)"
            (click)="activateReplying()"
            class="replies__activate-reply"
            [matTooltip]="'app.course.comment.reply-label-tooltip' | translate"
          >
            {{ 'app.course.comment.reply-label' | translate }}
          </div>
        </ng-container>
        <form [formGroup]="replyForm" *ngIf="editMode" class="replyForm">
          <div class="input">
            <div class="text-area-wrapper">
              <textarea
                #commentsInput
                class="textarea"
                formControlName="text"
                (input)="autoGrow($event.target)"
                [placeholder]="'app.course.comment.admin-reply-input-placeholder' | translate"
                (focus)="onInputFocus()"
                (blur)="onInputBlur()"
              >
              </textarea>
              <div class="help-block" *ngIf="submitted">
                <div
                  *ngIf="f.text?.errors && f.text?.dirty"
                  class="invalid-feedback d-block"
                >
                  <div
                    *ngIf="f.text?.errors?.minlength"
                    class="alert alert-danger"
                    role="alert"
                  >
                    {{ 'app.course.comment.input-error-too-short' | translate }}
                  </div>
                  <div
                    *ngIf="f.text?.errors?.required"
                    class="alert alert-danger"
                    role="alert"
                  >
                    {{ 'app.course.comment.input-error-empty' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons_container">
              <button
                class="btn outline primary btn_iconed"
                [ngClass]="{disabled: (isFormDisabled | async)}"
                (click)="submit($event)"
              >
                <span class="fa fa-envelope"></span>
              </button>
              <ng-container *ngIf="isMediaCommentEnabled">
                <app-media-capture
                  *ngIf="editCommentsAllowed$ | async"
                  (recordSuccess)="mediaRecordSuccess($event)"
                  (recordError)="mediaRecordError($event)"
                  (recordStatusChange)="recordStatusChange($event)"
                />
              </ng-container>
              <button
                class="btn outline primary"
                *ngIf="editMode"
                (click)="toggleVisibility()"
              >
                {{ currentVisibility == CommentVisibility.published ?
                "öffentlich" : "nicht öffentlich" }}
              </button>
            </div>
          </div>
          <div
            class="replyForm__recommendations"
            *ngIf="getResponseRecommendations(comment).length"
            [ngClass]="{expanded: recommendationsExpanded$ | async}"
          >
            <div
              class="replyForm__recommendations_title"
              (click)="recommendationsExpanded$.next(!recommendationsExpanded$.getValue())"
            >
              <i
                class="expand-icon fas"
                [ngClass]="{
                'fa-chevron-up': recommendationsExpanded$ | async,
                'fa-chevron-down': !(recommendationsExpanded$ | async),
              }"
              ></i>
              Antwortvorschläge ({{getResponseRecommendations(comment).length}})
            </div>
            <div
              *ngFor="let recommendation of getResponseRecommendations(comment)"
              class="replyForm__recommendation"
              (click)="setReplyText(recommendation)"
            >
              <div
                class="replyForm__recommendation_content"
                [innerHTML]="recommendation"
              ></div>
            </div>
          </div>
        </form>

        <ng-container *ngIf="(mediaRecordResult$ | async)?.length">
          <div
            *ngFor="let mediaItem of (mediaRecordResult$ | async)"
            class="comment__upload-result"
          >
            <p class="alert alert-success">
              Link zum Video:
              <a target="_blank" href="{{mediaItem?.publicLink}}"
                >{{mediaItem?.publicLink}}</a
              >. Dieses steht in wenigen Minuten zur Verfügung.
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="mediaResultError$ | async">
          <div class="comment__upload-result">
            <p class="alert alert-danger">{{mediaResultError$ | async}}</p>
          </div>
        </ng-container>
      </div>
    </div>
    <button
      class="expander"
      (click)="showReplies = !showReplies"
      *ngIf="isExpandable"
    ></button>
  </div>
</div>
