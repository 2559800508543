import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChatbotDisabledBy } from '@models/section';
import { MatTooltip } from '@angular/material/tooltip';
import { NgClass, NgIf } from '@angular/common';
import { ButtonGroupComponent } from '@components/button-group/button-group.component';

@Component({
    selector: 'app-disable-chatbot',
    templateUrl: './disable-chatbot.component.html',
    styleUrls: ['./disable-chatbot.component.scss'],
    imports: [
        MatTooltip,
        NgClass,
        ButtonGroupComponent,
    ],
})
export class DisableChatbotComponent {
  @Input({ required: true }) public chatbotDisabledBy: ChatbotDisabledBy = null;
  @Output() public chatbotDisabledByChanged = new EventEmitter<ChatbotDisabledBy>();
  public readonly ChatbotDisabledBy = ChatbotDisabledBy;

  public setChatbotDisabled(value: ChatbotDisabledBy) {
    this.chatbotDisabledByChanged.emit(value);
  }
}
