import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
    selector: 'app-button-group',
    template: `
    <div class="app-button-group">
      <ng-content></ng-content>
    </div>
  `,
    styleUrl: './button-group.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule],
})
export class ButtonGroupComponent {
}
