import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs'; // ✅ Correct
import { TranslateLoader } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { DateTime } from 'luxon';

@Injectable()
export class HttpTranslationService implements TranslateLoader {

  constructor(private http: HttpClient) { }

  getTranslation(lang: string): Observable<unknown> {
    const timestamp = DateTime.now().toUnixInteger();
    return this.http.get(`/assets/i18n/${lang}.json?t=${timestamp}`).pipe(
    map((response: JSON) => {
          return response;
        }),
      );
  }
}
