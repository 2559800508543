// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.4.2
//   protoc               v4.25.6
// source: src/modules/activity/protobuf/activity.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "activity";

export interface Activity {
  timestamp: string;
  sessionId: string;
  path: string;
  type: string;
  chapterId: string;
  sectionId: string;
  value: string;
}

export interface CreateActivitiesRequest {
  activities: Activity[];
}

export interface CreateActivitiesResponse {
  created: number;
}

function createBaseActivity(): Activity {
  return { timestamp: "", sessionId: "", path: "", type: "", chapterId: "", sectionId: "", value: "" };
}

export const Activity: MessageFns<Activity> = {
  encode(message: Activity, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.timestamp !== "") {
      writer.uint32(10).string(message.timestamp);
    }
    if (message.sessionId !== "") {
      writer.uint32(18).string(message.sessionId);
    }
    if (message.path !== "") {
      writer.uint32(26).string(message.path);
    }
    if (message.type !== "") {
      writer.uint32(34).string(message.type);
    }
    if (message.chapterId !== "") {
      writer.uint32(42).string(message.chapterId);
    }
    if (message.sectionId !== "") {
      writer.uint32(50).string(message.sectionId);
    }
    if (message.value !== "") {
      writer.uint32(58).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Activity {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseActivity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.timestamp = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.sessionId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.path = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.type = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.chapterId = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.sectionId = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.value = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Activity {
    return {
      timestamp: isSet(object.timestamp) ? globalThis.String(object.timestamp) : "",
      sessionId: isSet(object.sessionId) ? globalThis.String(object.sessionId) : "",
      path: isSet(object.path) ? globalThis.String(object.path) : "",
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      chapterId: isSet(object.chapterId) ? globalThis.String(object.chapterId) : "",
      sectionId: isSet(object.sectionId) ? globalThis.String(object.sectionId) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: Activity): unknown {
    const obj: any = {};
    if (message.timestamp !== "") {
      obj.timestamp = message.timestamp;
    }
    if (message.sessionId !== "") {
      obj.sessionId = message.sessionId;
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    if (message.chapterId !== "") {
      obj.chapterId = message.chapterId;
    }
    if (message.sectionId !== "") {
      obj.sectionId = message.sectionId;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Activity>, I>>(base?: I): Activity {
    return Activity.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Activity>, I>>(object: I): Activity {
    const message = createBaseActivity();
    message.timestamp = object.timestamp ?? "";
    message.sessionId = object.sessionId ?? "";
    message.path = object.path ?? "";
    message.type = object.type ?? "";
    message.chapterId = object.chapterId ?? "";
    message.sectionId = object.sectionId ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseCreateActivitiesRequest(): CreateActivitiesRequest {
  return { activities: [] };
}

export const CreateActivitiesRequest: MessageFns<CreateActivitiesRequest> = {
  encode(message: CreateActivitiesRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.activities) {
      Activity.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateActivitiesRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateActivitiesRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.activities.push(Activity.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateActivitiesRequest {
    return {
      activities: globalThis.Array.isArray(object?.activities)
        ? object.activities.map((e: any) => Activity.fromJSON(e))
        : [],
    };
  },

  toJSON(message: CreateActivitiesRequest): unknown {
    const obj: any = {};
    if (message.activities?.length) {
      obj.activities = message.activities.map((e) => Activity.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateActivitiesRequest>, I>>(base?: I): CreateActivitiesRequest {
    return CreateActivitiesRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateActivitiesRequest>, I>>(object: I): CreateActivitiesRequest {
    const message = createBaseCreateActivitiesRequest();
    message.activities = object.activities?.map((e) => Activity.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateActivitiesResponse(): CreateActivitiesResponse {
  return { created: 0 };
}

export const CreateActivitiesResponse: MessageFns<CreateActivitiesResponse> = {
  encode(message: CreateActivitiesResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.created !== 0) {
      writer.uint32(8).int32(message.created);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CreateActivitiesResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateActivitiesResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.created = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateActivitiesResponse {
    return { created: isSet(object.created) ? globalThis.Number(object.created) : 0 };
  },

  toJSON(message: CreateActivitiesResponse): unknown {
    const obj: any = {};
    if (message.created !== 0) {
      obj.created = Math.round(message.created);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateActivitiesResponse>, I>>(base?: I): CreateActivitiesResponse {
    return CreateActivitiesResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateActivitiesResponse>, I>>(object: I): CreateActivitiesResponse {
    const message = createBaseCreateActivitiesResponse();
    message.created = object.created ?? 0;
    return message;
  },
};

export interface ActivityService {
  Create(request: CreateActivitiesRequest): Promise<CreateActivitiesResponse>;
}

export const ActivityServiceServiceName = "activity.ActivityService";
export class ActivityServiceClientImpl implements ActivityService {
  private readonly rpc: Rpc;
  private readonly service: string;
  constructor(rpc: Rpc, opts?: { service?: string }) {
    this.service = opts?.service || ActivityServiceServiceName;
    this.rpc = rpc;
    this.Create = this.Create.bind(this);
  }
  Create(request: CreateActivitiesRequest): Promise<CreateActivitiesResponse> {
    const data = CreateActivitiesRequest.encode(request).finish();
    const promise = this.rpc.request(this.service, "Create", data);
    return promise.then((data) => CreateActivitiesResponse.decode(new BinaryReader(data)));
  }
}

interface Rpc {
  request(service: string, method: string, data: Uint8Array): Promise<Uint8Array>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
